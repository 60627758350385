import React from "react";

import Layout from "components/Layout";
import Intro from "components/Intro";
import { SEO } from "components/SEO";
import HomeGrid from "components/HomeGrid/HomeGrid";

const IndexPage = () => {
	return (
		<Layout pageName="home">
			<SEO
				title="Blue Medical AG, Switzerland"
				description="Packaging services for the medical technology and healthcare industry by Swiss company Blue Medical."
			/>
			<Intro className="home-intro">
				<p>
					Swiss contract packaging company, providing services for the
					medical technology and healthcare industry.
				</p>
			</Intro>
			<HomeGrid />
		</Layout>
	);
};

export default IndexPage;
