import styled from "styled-components";

export const StyledHomeGrid = styled.section`
	display: grid;
	justify-content: center;
	margin-bottom: 3.75rem;

	grid-gap: 0.75rem;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: auto 22.5rem 22.5rem;
	grid-template-areas:
		"hero hero hero hero"
		"a b news news"
		"c d image image";

	@media (min-width: 40.01px) and (max-width: 75rem) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-template-areas:
			"hero hero"
			"a b"
			"c d"
			"image image"
			"news news";
	}

	@media (max-width: 40rem) {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-template-areas:
			"hero"
			"a"
			"b"
			"c"
			"d"
			"image"
			"news";
	}

	.grid_box {
		font-family: var(--medium);
		h1 {
			font-size: 2.25rem;
			line-height: 3rem;
			text-transform: none;
			margin-bottom: 1.25rem;
		}
		a {
			font-family: var(--medium);
			height: 100%;
			padding: 1.625rem 2.25rem 2rem;

			@media (min-width: 40.01px) and (max-width: 66.38rem) {
				padding: 1.5rem;
			}

			@media (max-width: 40rem) {
				padding: 1.5rem;
			}

			svg {
				transition: transform 200ms ease-out;
				transform: translate3d(-16px, 0, 0);
				will-change: transform;
			}

			&:hover,
			&:focus {
				svg {
					transform: translate3d(0, 0, 0);
				}
			}
		}
		p {
			font-size: 1.5rem;
			line-height: 2.25rem;
			text-transform: none;
			margin: 0;
		}

		&.img_hero {
			grid-area: hero;
			position: relative;

			@media (min-width: 40.01px) and (max-width: 66.38rem) {
				min-height: 29.25rem;
			}

			@media (max-width: 40rem) {
				min-height: 22.5rem;
			}

			.gatsby-image-wrapper {
				height: 100%;
			}

			img {
				@media (min-width: 40.01px) and (max-width: 66.38rem) {
					object-position: left center !important;
				}
			}

			.cta-container {
				display: flex;
				flex-direction: column;
				position: absolute;
				z-index: 1000;
				top: 1.5rem;
				left: 1.5rem;

				a {
					padding: 0;
				}

				svg {
					/* width: 2rem; */
					transform: rotate(90deg) scale(2);
					margin-bottom: 2.12rem;
					margin-left: 1.5rem;
				}

				button {
					display: block;
					border: none;
					padding: 1.063rem 1.38rem 0.94rem;
				}
			}
		}

		&.text_box {
			display: flex;
			.content {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
				overflow: hidden;

				svg {
					margin-top: auto;
				}
			}

			/* @media (min-width: 40.01px) and (max-width: 66.38rem) {
				padding: 2.25rem;
			}
			@media (max-width: 40rem) {
				padding: 1.5rem;
			} */

			.p-small {
				font-size: 1rem;
				line-height: 1.5rem;
				margin-bottom: 1.5rem;
			}

			&.blue {
				background-color: var(--blue);
				color: var(--white);

				a {
					color: var(--white);
				}

				svg {
					polygon {
						fill: var(--white);
					}
				}
			}
			&.white {
				background-color: var(--white);
				color: var(--blue);
				border: 2px solid var(--gray24);

				a {
					color: var(--blue);
				}
			}
			&.gray6 {
				background-color: var(--gray6);
				color: var(--blue);
				a {
					color: var(--blue);
				}
			}
			&.gray12 {
				background-color: var(--gray12);
				color: var(--blue);
				a {
					color: var(--blue);
				}
			}
			&.gray24 {
				background-color: var(--gray24);
				color: var(--white);
				a {
					color: var(--white);
				}

				svg {
					polygon {
						fill: var(--white);
					}
				}
			}
			&.gray42 {
				background-color: var(--gray42);
				color: var(--white);
				a {
					color: var(--white);
				}
				svg {
					polygon {
						fill: var(--white);
					}
				}
			}

			.news_links {
				margin-top: auto;
				a {
					padding-right: 1.5rem;
				}
			}
		}
		&.packaging {
			grid-area: a;
		}
		&.news {
			grid-area: news;
			justify-content: flex-start !important;

			.content {
				padding: 1.88rem 2.125rem 1.38rem;

				@media (min-width: 40.01px) and (max-width: 66.38rem) {
					padding: 1.5rem;
				}

				@media (max-width: 40rem) {
					padding: 1.5rem;
				}

				svg {
					margin: 0;
				}
			}

			a {
				padding: 0;
			}
			p {
				position: relative;
				margin-bottom: 3.5rem;

				&:after {
					content: "";
					position: absolute;
					height: 0.19rem;
					width: 1.88rem;
					bottom: -2rem;
					left: 0;
					background-color: var(--blue);
				}
			}

			.dl-wrapper {
				display: inline-flex;
				align-items: center;

				svg {
					transform: none;
					margin-right: 0.5rem;
				}
			}

			.link-wrapper {
				svg {
					margin-right: 0.5rem;
				}

				span {
					display: inline-block;
				}
			}

			.link-wrapper-news {
				overflow: hidden;

				svg,
				span {
					transition: transform 200ms ease-out;
					transform: translate3d(-16px, 0, 0);
					will-change: transform;
				}

				&:hover,
				&:focus {
					svg,
					span {
						transform: translate3d(0, 0, 0);
					}
				}
			}

			.news-archive {
				svg {
					transform: translate3d(0, 0, 0);
				}
			}
		}
		&.quality {
			grid-area: b;
		}
		&.company {
			grid-area: c;

			.copy-large {
				display: block;
				margin-bottom: 0.62rem;

				@media (max-width: 40rem) {
					display: none;
				}
			}
		}
		&.career {
			grid-area: d;
		}
		&.img_employee {
			grid-area: image;

			.gatsby-image-wrapper {
				height: 100%;
			}
			img {
				object-fit: cover;
				width: 100%;
				height: 45.75rem;
			}
		}
	}
	/* .temp-color {
		background-color: #88e2ee !important;
	} */
`;
