import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Image from "gatsby-image";
import { ArrowSmallEx, ArrowLongEx, ArrowDownload } from "components/Arrow";
import { StyledHomeGrid } from "./HomeGrid.styled";

import pdfFile2 from "assets/pdf/BM_EN_Flyer-Packaging_2020-06_A4_RGB.pdf";

const HomeGrid = () => (
	<StaticQuery
		query={graphql`
			query {
				homeHero: file(
					relativePath: { eq: "home/BM_Home_Cleanroom_2928x1440.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1464) {
							...GatsbyImageSharpFluid
						}
					}
				}
				homeGrid: file(
					relativePath: { eq: "home/BM_Home_Packaging_1452x720.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 726) {
							...GatsbyImageSharpFluid
						}
					}
				}
				NewsQuery: allMarkdownRemark(
					sort: { order: DESC, fields: [frontmatter___date] }
				) {
					edges {
						node {
							fields {
								slug
							}
							excerpt(pruneLength: 100)
							frontmatter {
								date(formatString: "YYYY MMMM")
								title
							}
						}
					}
				}
			}
		`}
		render={(data) => (
			<StyledHomeGrid>
				<div className="grid_box img_hero img">
					<div className="cta-container">
						<Link to="/products/">
							<button>Explore our Products</button>
						</Link>
					</div>
					<Image
						fluid={data.homeHero.childImageSharp.fluid}
						alt="People packaging sterile medical technology products in a cleanroom."
					/>
				</div>
				<article className="grid_box packaging text_box blue">
					<Link to="/products/">
						<div className="content">
							<h1>Packaging</h1>
							<p className="p-small">
								We offer packaging services for disposable
								medical packaging and combination products for
								the medical technology sector and the healthcare
								industry.
							</p>
							<ArrowLongEx />
						</div>
					</Link>
				</article>

				<article className="grid_box quality text_box gray42">
					<Link to="/quality/">
						<div className="content">
							<h1>Quality</h1>
							<p className="p-small">
								Sterile packaging is a critical process and a
								compelling require&shy;ment for medical devices.
								All our service are in accordance with EN ISO
								13485:2016.
							</p>
							<ArrowLongEx />
						</div>
					</Link>
				</article>

				<article className="grid_box company text_box gray12">
					<Link to="/company/">
						<div className="content">
							<h1>Company</h1>
							<p className="p-small">
								We are celebrating our fifteenth birthday this
								year. Ever since we have been service renown
								inter&shy;national manu&shy;facturers and
								distributors of medical devices.
							</p>
							<ArrowLongEx />
						</div>
					</Link>
				</article>
				<article className="grid_box career text_box gray24">
					<Link to="/company/#career">
						<div className="content">
							<h1>Career</h1>
							<p className="p-small">
								Interested to become part of the Blue Medical
								team?
							</p>
							<ArrowLongEx />
						</div>
					</Link>
				</article>

				<article className="grid_box news text_box copy-large white">
					<div className="content">
						<span className="mono">
							{data.NewsQuery.edges[0].node.frontmatter.date}
						</span>
						<p>{data.NewsQuery.edges[0].node.excerpt}</p>

						<div className="news_links">
							<div className="dl-wrapper news-archive">
								<a
									href={pdfFile2}
									target="_blank"
									rel="noopener noreferrer"
								>
									<ArrowDownload />
									Overview
								</a>
							</div>
							<div className="link-wrapper news-archive">
								<Link to="/news/">
									<ArrowSmallEx />
									News Archive
								</Link>
							</div>
						</div>
					</div>
				</article>

				<div className="grid_box img_employee img">
					<Image
						fluid={data.homeGrid.childImageSharp.fluid}
						alt="Packaging machine for the medical technology and healthcare industry."
					/>
				</div>
			</StyledHomeGrid>
		)}
	/>
);

export default HomeGrid;
